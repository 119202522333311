<template>
    <div class="page-container">
        <md-app md-waterfall md-mode="fixed">
            <md-app-toolbar class="md-primary">
                <div class="md-toolbar-row">
                    <div class="md-toolbar-section-start">
                        <img src="/img/logo-stock.png" alt="stock.ftvaccess.fr" class="logo" />
                        <h3 class="md-title">stock.ftvaccess.fr</h3>
                    </div>
                    <div class="md-toolbar-section-end">
                        <md-menu md-direction="bottom-end" :mdCloseOnClick="true" :mdCloseOnSelect="true">
                            <md-button class="md-icon-button" md-menu-trigger>
                                <md-icon>more_vert</md-icon>
                            </md-button>
                            <md-menu-content>
                                <md-menu-item v.if="$store.getters.logged" @click="$router.push('/stock')">
                                    <span>Recherche de programmes</span>
                                    <md-icon>search</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/password')">
                                    <span>Changer mot de passe</span>
                                    <md-icon>lock</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/confidentialite')">
                                    <span>Politique de confidentialité</span>
                                    <md-icon>policy</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/cgu')">
                                    <span>CGU</span>
                                    <md-icon>description</md-icon>
                                </md-menu-item>
                                <md-menu-item @click="$router.push('/legal')">
                                    <span>Mentions légales</span>
                                    <md-icon>gavel</md-icon>
                                </md-menu-item>
                                <md-menu-item  @click="logout">
                                    <span>Déconnexion</span>
                                    <md-icon>logout</md-icon>
                                </md-menu-item>
                            </md-menu-content>
                        </md-menu>
                    </div>
                </div>
            </md-app-toolbar>
            <md-app-content class="md-scrollbar md-alignment-top-center md-layout">
                <md-content>
                    <h1 class="md-title">Conditions Générale d’Utilisation</h1>
                    <h2 class="md-subheading">(Octobre 2022)</h2>
                    <h3 class="text-justify">Compte utilisateur</h3>
                    <p class="md-body-1 text-justify">
                        La création d’un compte utilisateur client est indispensable afin d’accéder au Site.
                    </p>
                    <p class="md-body-1 text-justify">
                        Tout compte utilisateur est créé par les services internes de france.tv studio. 
                    </p>
                    <p class="md-body-1 text-justify">
                        L’identifiant et le mot de passe de l’utilisateur sont strictement personnels et confidentiels. L’utilisateur est seul responsable de la garde et de l’utilisation de ces informations.<br />
                        En cas de perte ou de détournement de ses identifiants de connexion, l’utilisateur concerné s’engage à informer sans délai france.tv studio par écrit de l’utilisation non autorisée de son compte utilisateur et à modifier son mot de passe. 
                    </p>
                    <h3 class="text-justify">Confidentialité</h3>
                    <p class="md-body-1 text-justify">
                        L’utilisateur s’engage à ne pas divulguer directement ou indirectement et à conserver la plus stricte confidentialité concernant les contenus consultés et plus généralement sur toute information qu’il pourrait recueillir sur france.tv studio ainsi que sur toute entité ou partenaire du groupe auquel elle appartient. 
                    </p>
                    <p class="md-body-1 text-justify">
                        L’engagement de confidentialité est conclu pour la durée couvrant l’exécution des prestations et la consultation du Site et reste en vigueur pour une durée de 3 (trois) ans à l’issue de l’exécution des prestations ou de la consultation. 
                    </p>
                    <p class="md-body-1 text-justify">
                        L’utilisateur assume l’entière responsabilité de toute divulgation non expressément autorisée.<br />
                        Le Prestataire reconnaît que tout manquement à ces obligations léserait gravement les intérêts de france.tv studio qui se réserve le droit d’engager toute action aux plans civil et pénal.
                    </p>
                    <h3 class="text-justify">Droit de propriété intellectuelle</h3>
                    <p class="md-body-1 text-justify">
                        L’ensemble des éléments du Site (notamment la structure, tous les textes, vidéos, données, affiches, logos, marques et autres éléments reproduits) sont réservés et protégés par le droit de la propriété intellectuelle, notamment les droits d’auteur, droits voisins, droits des marques.
                    </p>
                    <p class="md-body-1 text-justify">
                        Toute utilisation non autorisée de tout ou partie des éléments du Site et notamment les vidéos (piratage, contrefaçon, contournement des restrictions de diffusion, etc.) pourra donner lieu à des poursuites.
                    </p>
                    <p class="md-body-1 text-justify">
                        Il est expressément interdit à tout utilisateur, quelle que soit sa qualité ou son activité, de mettre en place un mécanisme de référencement systématique de tout ou partie des contenus du Site (vidéos, actualités, etc.), notamment au moyen de liens hypertexte profonds, aux fins de proposer, sur tout autre site, service ou application, un référencement desdits contenus à destination du public (notamment tout service de grille de programmes ou assimilé, etc.) et ce, à toutes fins notamment commerciales.
                    </p>
                    <h3 class="text-justify">Données personnelles et cookies</h3>
                    <p class="md-body-1 text-justify">
                        Veuillez consulter notre Politique de confidentialité en cliquant <router-link to="/confidentialite">ICI</router-link>.
                    </p>
                    <h3 class="text-justify">Responsabilité et garanties</h3>
                    <p class="md-body-1 text-justify">
                        De manière générale, l’utilisateur garantit france.tv studio contre tout recours ou action et leurs conséquences pécuniaires éventuelles de toute personne, fondée sur ou résultant directement ou indirectement de ses agissements, ou découlant de l’utilisation par l’utilisateur du Site ainsi que de toute violation supposée des CGU ou des dispositions réglementaires en vigueur et tient france.tv studio quitte et indemne de tout recours y compris contentieux qui pourrait en résulter. Cette garantie couvre les indemnités qui seraient éventuellement dues ainsi que les honoraires d'avocats et frais de justice dans une limite raisonnable.
                    </p>
                    <h3 class="text-justify">Protection du Site</h3>
                    <p class="md-body-1 text-justify">
                        L’utilisateur s'interdit d'utiliser tout matériel ou logiciel susceptible d'altérer, d'entraver ou de fausser le fonctionnement du Site, ou d'introduire toute donnée dans un système de traitement automatisé de données de france.tv studio susceptible d'altérer, d'entraver ou de fausser le fonctionnement normal du Site, et notamment de contourner les restrictions à l'utilisation définies par les présentes CGU.
                    </p>
                    <h3 class="text-justify">Recommandation particulière aux utilisateurs internationaux</h3>
                    <p class="md-body-1 text-justify">
                        france.tv studio autorise l’accès au Site depuis tout pays, sous réserve de la législation locale en vigueur et des restrictions applicables. Tout utilisateur accédant au Site depuis un poste informatique situé hors du territoire français reconnaît expressément avoir pris connaissance, compris et accepté sans réserve les termes des présentes CGU générales et s’engage à respecter la législation locale et toutes les restrictions applicables au pays concerné.
                    </p>
                    <h3 class="text-justify">Dispositions finales</h3>
                    <p class="md-body-1 text-justify">
                        Si une partie quelconque des dispositions des présentes CGU devait s'avérer illégale, invalide ou inapplicable pour quelque raison que ce soit, le terme ou les termes en question seraient déclarés inexistants et les termes restants garderaient toute leur force et leur portée et continueraient à être applicables. Les termes déclarés inexistants seront remplacés par des termes qui se rapprocheront le plus quant à leur contenu de la clause annulée.
                    </p>
                    <p class="md-body-1 text-justify">
                        Les CGU sont soumises à la loi française. Tout désaccord ou litige qui ne se règle pas par la voie amiable sera soumis aux Tribunaux de Paris.
                    </p>
                </md-content>
            </md-app-content>
        </md-app>
    </div>
</template>
      

<script>

export default {
  name: 'Cgu',
  data(){
      return {
          loader: null,
      }
  },
  methods:{
      logout(){
            this.$store.dispatch('user/logout')
            .then(() => {
                this.$router.push({path: '/'});
            })
            .catch(() => {
                this.$alert("Il n'a pas été possible de vous deconnecter",'Error','error');
            })
        }
  }
  
}
</script>
<style lang="scss" scoped>
.md-app {
    min-height: 100vh;
    
    border: 1px solid rgba(#000, .12);
  }
  .text-justify{
    text-align: justify;
  }
img.logo{
    max-height: 50px;
}
.md-layout-item{
    flex-basis: 400px;
    min-width: 300px;
    max-width: 400px;
}
</style>